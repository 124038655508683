import { getSettingsData } from "../api"
import {
    GET_SETTINGS_REQUEST,
    GET_SETTINGS_SUCCESS,
    GET_SETTINGS_FAILURE,
} from "../const";

const request = () => ({ type: GET_SETTINGS_REQUEST });

const success = (settings) => ({
    type: GET_SETTINGS_SUCCESS,
    payload: { settings },
});
const failure = () => ({ type: GET_SETTINGS_FAILURE });

export const receiveSettings = () => async (dispatch) => {
    dispatch(request());
    try {
        const { data: receiveSettingsData } = await getSettingsData();

        dispatch(success(receiveSettingsData));
    } catch (e) {
        console.log(e);
        dispatch(failure());
    };
};
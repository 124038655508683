import {
    GET_SETTINGS_REQUEST,
    GET_SETTINGS_SUCCESS,
    GET_SETTINGS_FAILURE,
} from "../const";


const initialState = {
    min_checkout: 0,
    loading: false
};

const handlers = {
    [GET_SETTINGS_REQUEST]: (state) => ({ ...state, loading: true }),
    [GET_SETTINGS_SUCCESS]: (state, { payload: { settings } }) => ({
        ...state,
        loading: false,
        ...settings,
    }),
    [GET_SETTINGS_FAILURE]: (state) => ({ ...state, loading: false }),
    DEFAULT: (state) => state,
};


const cartSettingsReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};

export default cartSettingsReducer;
